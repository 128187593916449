export const PADDINGS = {
  commonInPercent: '8.33%',
  commonInPixels: '120px',
  commonInPixelsLarge: '50px',
  commonInPixelsMedium: '32px',
  commonInPixelsSmall: '16px',
  paddingBetweenSectionsLarge: '120px',
  paddingBetweenSectionsMedium: '64px',
  paddingBetweenSectionsSmall: '32px',
};
