import {
  AuthResponseDto,
  UserResponseDto,
  ContractResponseDto,
} from '@/api/generated';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as actions from './actions';

interface InitialState extends AuthResponseDto {
  contracts: ContractResponseDto[];
}

const initialState: InitialState = {
  token: '',
  user: {} as UserResponseDto,
  contracts: [],
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      actions.setUserData,
      (state, { payload }: PayloadAction<AuthResponseDto>) => {
        state.token = payload.token;
        state.user = payload.user;
      }
    )
    .addCase(actions.logOut, (state) => {
      state.token = '';
      state.user = {} as UserResponseDto;
    })
    .addCase(
      actions.updateUserData,
      (state, { payload }: PayloadAction<UserResponseDto>) => {
        state.user = payload;
      }
    )
    .addCase(
      actions.setContracts,
      (state, { payload }: PayloadAction<ContractResponseDto[]>) => {
        state.contracts = payload;
      }
    )
    .addCase(
      actions.addContract,
      (state, { payload }: PayloadAction<ContractResponseDto>) => {
        state.contracts.push(payload);
      }
    )
    .addCase(
      actions.updateContract,
      (state, { payload }: PayloadAction<ContractResponseDto>) => {
        const index = state.contracts.findIndex(
          (contract) => contract.id === payload.id
        );

        if (index !== -1) {
          state.contracts[index] = payload;
        }
      }
    );
});
