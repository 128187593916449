import {
  AuthResponseDto,
  ContractResponseDto,
  UserResponseDto,
} from '@/api/generated';
import { createAction } from '@reduxjs/toolkit';
import { ActionType } from './types';

export const setUserData = createAction<AuthResponseDto>(
  ActionType.SET_USER_DATA
);

export const logOut = createAction(ActionType.LOG_OUT);

export const updateUserData = createAction<UserResponseDto>(
  ActionType.UPDATE_USER_DATA
);

export const setContracts = createAction<ContractResponseDto[]>(
  ActionType.SET_MY_CONTRACTS
);

export const addContract = createAction<ContractResponseDto>(
  ActionType.ADD_CONTRACT
);

export const updateContract = createAction<ContractResponseDto>(
  ActionType.UPDATE_CONTRACT
);
