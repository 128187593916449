export enum ActionType {
  SIGN_IN = 'ADMIN/SIGN_IN',
  SET_TOKEN = 'ADMIN/SET_TOKEN',
  GET_MANAGERS = 'ADMIN/GET_MANAGERS',
  SET_MANAGERS = 'ADMIN/SET_MANAGERS',
  POST_ADD_MANAGER = 'ADMIN/POST_ADD_MANAGER',
  ADD_MANAGER = 'ADMIN/ADD_MANAGER',
  GET_USERS = 'ADMIN/GET_USERS',
  SET_USERS = 'ADMIN/SET_USERS',
  PATCH_ADD_USER_FOR_MANAGER = 'ADMIN/PATCH_ADD_USER_FOR_MANAGER',
  ADD_USER_FOR_MANAGER = 'ADMIN/ADD_USER_FOR_MANAGER',
  GET_ALL_CONTRACTS = 'ADMIN/GET_ALL_CONTRACTS',
  SET_CONTRACTS = 'ADMIN/SET_CONTRACTS',
  PATCH_UPDATE_CONTRACT = 'ADMIN/POST_UPDATE_CONTRACT',
  UPDATE_CONTRACT = 'ADMIN/UPDATE_CONTRACT',
}
