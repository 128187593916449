import {
  UserResponseDto,
  ContractResponseDto,
  AdminSignInResponseDto,
  ManagerResponseDto,
  AddManagerRequestDto,
} from '@/api/generated';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as actions from './actions';

interface InitialState extends AdminSignInResponseDto {
  contracts: ContractResponseDto[];
  users: UserResponseDto[];
  managers: ManagerResponseDto[];
}

const initialState: InitialState = {
  token: '',
  users: [],
  managers: [],
  contracts: [],
};

export const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      actions.setToken,
      (state, { payload }: PayloadAction<AdminSignInResponseDto>) => {
        state.token = payload.token;
      }
    )
    .addCase(
      actions.setManagers,
      (state, { payload }: PayloadAction<ManagerResponseDto[]>) => {
        state.managers = payload;
      }
    )
    .addCase(
      actions.addManager,
      (state, { payload }: PayloadAction<ManagerResponseDto>) => {
        state.managers.push(payload);
      }
    )
    .addCase(
      actions.setUsers,
      (state, { payload }: PayloadAction<UserResponseDto[]>) => {
        state.users = payload;
      }
    )
    .addCase(
      actions.setContracts,
      (state, { payload }: PayloadAction<ContractResponseDto[]>) => {
        state.contracts = payload;
      }
    )
    .addCase(
      actions.updateContract,
      (state, { payload }: PayloadAction<ContractResponseDto>) => {
        const index = state.contracts.findIndex(
          (contract) => contract.id === payload.id
        );
        if (index !== -1) {
          state.contracts[index].sum = payload.sum;
        }
      }
    )
    .addCase(
      actions.addUserForManager,
      (state, { payload }: PayloadAction<AddManagerRequestDto>) => {
        const manager = state.managers.find(
          (manager) => manager.id === payload.managerId
        );
        const user = state.users.find((user) => user.id === payload.userId);
        if (manager && user) {
          manager.users.push(user);
        }
      }
    );
});
