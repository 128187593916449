export const TYPOGRAPHY = {
  title1SemiBold48: `
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  `,
  title2SemiBold36: `
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  `,
  title3SemiBold32: `
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
  `,
  title4Medium28: `
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
  `,
  headline1Medium24: `
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  `,
  headline2SemiBold22: `
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  `,
  headline3SemiBold20: `
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  `,
  body1Regular18: `
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  `,
  body1SemiBold18: `
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  `,
  body2Regular16: `
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  body2Medium16: `
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  caption1Regular14: `
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  `,
  caption1Medium14: `
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  `,
  caption2Regular10: `
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  `,
};
