import { combineReducers } from 'redux';

import * as auth from './ducks/auth';
import * as admin from './ducks/admin';

export const reducer = combineReducers({
  auth: auth.authReducer,
  admin: admin.adminReducer,
});

export const actions = {
  auth: auth.actions,
  admin: admin.actions,
};

export const selectors = {
  auth: auth.selectors,
  admin: admin.selectors,
};
