import {
  AddManagerRequestDto,
  AdminSignInResponseDto,
  ContractResponseDto,
  ManagerResponseDto,
  UserResponseDto,
} from '@/api/generated';
import { createAction } from '@reduxjs/toolkit';
import { ActionType } from './types';

export const setToken = createAction<AdminSignInResponseDto>(
  ActionType.SET_TOKEN
);

export const setManagers = createAction<ManagerResponseDto[]>(
  ActionType.SET_MANAGERS
);

export const addManager = createAction<ManagerResponseDto>(
  ActionType.ADD_MANAGER
);

export const setUsers = createAction<UserResponseDto[]>(ActionType.SET_USERS);

export const addUserForManager = createAction<AddManagerRequestDto>(
  ActionType.ADD_USER_FOR_MANAGER
);

export const setContracts = createAction<ContractResponseDto[]>(
  ActionType.SET_CONTRACTS
);

export const updateContract = createAction<ContractResponseDto>(
  ActionType.UPDATE_CONTRACT
);
