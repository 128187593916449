export const LINKS = {
  index: '/',
  calculator: '/calculator',
  profile: '/profile',
  privacyPolicy: '',
  personalData: '/docs/Согласие-о-персданых.pdf',
  singIn: '/sign-in',
  signUp: '/sign-up',
  resetPassword: '/reset-password',
  admin: '/admin',
};
