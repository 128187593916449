import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../';

export const selectAdminToken = (state: RootState) => state.admin.token;

export const selectAdminManagers = (state: RootState) => state.admin.managers;

export const selectAdminUsers = (state: RootState) => state.admin.users;

export const selectAdminContracts = (state: RootState) => state.admin.contracts;

export const selectAdminFilteredContracts = createSelector(
  selectAdminContracts,
  (state) => state.filter((contract) => Number(contract.sum) === 0)
);
