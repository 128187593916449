import { adminHttp } from '@/services/adminHttp';
import { AnyAction, Dispatch } from 'redux';
import { REHYDRATE } from 'redux-persist';

import { http } from '../../services/http';
import { actions } from '../ducks';

export const authMiddleware =
  () =>
  (next: Dispatch) =>
  (action: AnyAction): AnyAction => {
    if (action.type === actions.auth.setUserData.type) {
      action.payload && http.setAuthorizationHeader(action.payload.token);
    }
    if (action.type === actions.admin.setToken.type) {
      action.payload && adminHttp.setAuthorizationHeader(action.payload.token);
    }

    if (action.type === REHYDRATE) {
      if (action.payload) {
        action.payload.auth?.token &&
          http.setAuthorizationHeader(action.payload.auth?.token);
        action.payload.admin?.token &&
          adminHttp.setAuthorizationHeader(action.payload.admin?.token);
      }
    }

    return next(action);
  };
