export enum ActionType {
  SET_USER_DATA = 'AUTH/SET_USER_DATA',
  POST_REGISTRATION = 'AUTH/POST_REGISTRATION',
  POST_LOGIN_BY_PHONE = 'AUTH/POST_LOGIN_BY_PHONE',
  POST_LOGIN_BY_EMAIL = 'AUTH/POST_LOGIN_BY_EMAIL',
  POST_RESET_PASSWORD = 'AUTH/POST_RESET_PASSWORD',
  LOG_OUT = 'AUTH/LOG_OUT',
  POST_UPDATE_USER = 'AUTH/POST_UPDATE_USER',
  UPDATE_USER_DATA = 'AUTH/UPDATE_USER_DATA',
  SET_MY_CONTRACTS = 'AUTH/SET_MY_CONTRACTS',
  FETCH_MY_CONTRACTS = 'AUTH/FETCH_MY_CONTRACTS',
  POST_ADD_CONTRACT = 'AUTH/POST_ADD_CONTRACT',
  ADD_CONTRACT = 'AUTH/ADD_CONTRACT',
  PATCH_CONTRACT = 'AUTH/PATCH_CONTRACT',
  UPDATE_CONTRACT = 'AUTH/UPDATE_CONTRACT',
}
