import { API_URL } from '@/constants';
import Axios from 'axios';
import { Http } from './http';

export const adminHttp = new Http(
  Axios.create({
    baseURL: `${API_URL.baseUrl}/admin`,
    timeout: 60000,
  })
);
