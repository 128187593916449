export const COLORS = {
  white: '#fff',
  black: '#14142A',
  black2: '#292D32',
  grayDarker: '#4E4B66',
  gray: '#A0A3BD',
  gray1: '#DCDDE6',
  grayLight: '#F7F9FA',
  grayLight2: '#F0F1F5',
  grayLighter: '#F9F9FA',
  primaryDark: '#007FBC',
  primaryDefault: '#4F98CA',
  primaryLight: '#E8F1FF',
  secondaryDark: '#B9D6EA',
  additionalRed: '#DD343F',
  additionalGreen: '#36C186',
  additionalYellow: '#FED82E',
  blue1: '#4F98CA',
};
