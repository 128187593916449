import { COLORS } from './colors';
import { TYPOGRAPHY } from './typography';

const getCommon = () => `
  width: 100%;
  max-width: 224px;
  transition: all 0.3s ease-out; 
  ${TYPOGRAPHY.body2Medium16};
`;

const getPrimaryStyles = () => `
  ${getCommon()}

  color: ${COLORS.white};
  background-color: ${COLORS.primaryDefault};

  :hover,
  :focus,
  :active {
    background-color: ${COLORS.primaryDark};
  }
  :focus {
    box-shadow: 0px 0px 0px 6px rgba(0, 127, 188, 0.2);
  }
  :active {
    box-shadow: 0px 5px 14px rgba(79, 152, 202, 0.4);
  }
  :disabled {
    background-color: ${COLORS.gray};
    cursor: auto;
  }
`;

const getSecondaryStyles = () => `
  ${getCommon()}

  color: ${COLORS.primaryDefault};
  background-color: ${COLORS.primaryLight};

  :hover,
  :focus,
  :active {
    background-color: ${COLORS.secondaryDark};
  }
  :focus {
    box-shadow: 0px 0px 0px 6px rgba(0, 127, 188, 0.04);
  }
  :active {
    box-shadow: 0px 5px 14px rgba(79, 152, 202, 0.2);
  }
  :disabled {
    background-color: ${COLORS.grayLight};
    color: ${COLORS.gray};
    cursor: auto;
  }
`;

export const BUTTON_THEME = {
  primary: {
    large: `
      ${getPrimaryStyles()}
      padding: 16px;
      border-radius: 16px;
    `,
    small: `
      ${getPrimaryStyles()}
      padding: 8px;
      border-radius: 12px;
    `,
  },
  secondary: {
    large: `
      ${getSecondaryStyles()}
      padding: 16px;
      border-radius: 16px;
    `,
    small: `
      ${getSecondaryStyles()}
      padding: 8px;
      border-radius: 12px;
    `,
  },
};
